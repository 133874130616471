var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"ma-0"},[_c('v-toolbar',{attrs:{"flat":"","color":"#fafafa"}},[_c('v-row',{staticClass:"pt-2",attrs:{"dense":""}},[_c('v-toolbar-title',{staticClass:"ml-3 mt-2 title"},[_vm._v("File Uploads")]),_c('v-divider',{staticClass:"mx-4",attrs:{"vertical":"","inset":""}}),_c('v-col',{attrs:{"sm":"4","md":"3"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","background-color":"#fff","label":"Filter Uploads","prepend-inner-icon":"mdi-filter-outline"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-spacer'),_c('v-col',{attrs:{"sm":"4","md":"3"}},[_c('v-autocomplete',{attrs:{"outlined":"","dense":"","background-color":"#fff","prepend-inner-icon":"mdi-file-upload-outline","label":"Select Upload Type","return-object":"","items":_vm.uploadOptions,"item-text":"name","item-value":"id","menu-props":{ maxHeight: 215 }},on:{"change":_vm.openModal},model:{value:(_vm.uploadType),callback:function ($$v) {_vm.uploadType=$$v},expression:"uploadType"}})],1)],1)],1),_c('v-divider'),_c('v-data-table',{staticClass:"table",attrs:{"items":_vm.files,"headers":_vm.headers,"height":_vm.tableSize,"items-per-page":_vm.pageSize,"loading":_vm.loading,"loader-height":"2","search":_vm.search,"loading-text":"Loading File Uploads...Please wait","dense":"","no-data-text":"No File Uploads to Display","hide-default-footer":"","fixed-header":""},scopedSlots:_vm._u([{key:"item.file_name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formattedFileName(item.file_name))+" ")]}},{key:"item.upload_time",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.moment.utc(item.upload_time).local().format('MMM D - h:mm a'))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status !== 'Failed')?_c('v-chip',{staticClass:"mr-4",attrs:{"small":"","dark":"","color":_vm.getColor(item.status)}},[_c('span',{staticClass:"text-capitalize",staticStyle:{"display":"inline-block","margin":"0 auto","width":"70px","text-align":"center"}},[_vm._v(" "+_vm._s(item.status === 'Complete' ? 'Uploaded' : item.status)+" ")])]):_vm._e(),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(item.status === 'Failed')?_c('v-chip',_vm._g({staticClass:"mr-4",attrs:{"small":"","dark":"","color":_vm.getColor(item.status)},on:{"click":function($event){return _vm.showErrors(item.errors)}}},on),[_c('span',{staticClass:"text-capitalize",staticStyle:{"display":"inline-block","margin":"0 auto","width":"70px","text-align":"center"}},[_vm._v(" Errors ")])]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Click to View")])])]}}],null,true)}),_c('v-divider'),_c('v-toolbar',{attrs:{"flat":"","color":"#fafafa"}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-spacer'),_c('v-col',{staticClass:"mt-4",attrs:{"cols":"2","align":"center"}},[_c('i',[(_vm.search)?_c('p',{staticClass:"blue--text"},[_vm._v(_vm._s(_vm.items.length)+" Results")]):_vm._e()])]),_c('v-spacer'),_c('v-col',{staticStyle:{"max-width":"150px"},attrs:{"cols":"2","align":"right"}},[_c('v-select',{staticClass:"mt-5",attrs:{"disabled":_vm.loading,"dense":"","items":_vm.pageSizes,"label":"Items Per Page","select":"","menu-props":{ top: true, offsetY: true, maxHeight: 500 }},model:{value:(_vm.pageSize),callback:function ($$v) {_vm.pageSize=$$v},expression:"pageSize"}})],1),_c('v-btn',{staticClass:"mx-2",attrs:{"icon":"","large":"","disabled":_vm.disablePreviousPage},on:{"click":function($event){return _vm.previous()}}},[_c('v-icon',[_vm._v("mdi-chevron-left")])],1),_vm._v(" Page "+_vm._s(_vm.page)+" "),_c('v-btn',{staticClass:"mx-2",attrs:{"icon":"","large":"","disabled":_vm.disableNextPage},on:{"click":function($event){return _vm.next()}}},[_c('v-icon',[_vm._v("mdi-chevron-right")])],1)],1)],1),_c(_vm.component,{key:_vm.componentKey,tag:"Component",attrs:{"value":_vm.modal,"uploadType":_vm.uploadType},on:{"refresh":_vm.getLogs,"update:value":function($event){_vm.modal=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }