var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"ma-0"},[_c('v-toolbar',{attrs:{"flat":"","color":"#fafafa"}},[_c('v-row',{staticClass:"pt-2",attrs:{"dense":""}},[_c('v-toolbar-title',{staticClass:"ml-3 mt-2 title"},[_vm._v("Period Closing")]),_c('v-divider',{staticClass:"mx-4",attrs:{"vertical":"","inset":""}}),_c('v-col',{staticStyle:{"max-width":"200px"}},[_c('v-autocomplete',{attrs:{"prepend-inner-icon":"mdi-filter-outline","outlined":"","dense":"","background-color":"#fff","label":"Year","items":_vm.years,"item-text":"description","item-value":"id","menu-props":{ maxHeight: 215 }},model:{value:(_vm.year),callback:function ($$v) {_vm.year=$$v},expression:"year"}})],1),_c('v-col',{staticStyle:{"max-width":"200px"}},[_c('v-autocomplete',{attrs:{"prepend-inner-icon":"mdi-filter-outline","outlined":"","dense":"","background-color":"#fff","label":"Period","items":_vm.periods,"item-text":"description","item-value":"id","menu-props":{ maxHeight: 215 }},model:{value:(_vm.period),callback:function ($$v) {_vm.period=$$v},expression:"period"}})],1),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"white--text mx-2 mt-1",attrs:{"disabled":!_vm.billingUser || !_vm.period || !_vm.year,"height":"40","color":"green"},on:{"click":function($event){return _vm.getClosingSteps(_vm.period, _vm.year)}}},on),[_vm._v(" GO ")])]}}])},[_c('span',[_vm._v("Start Closing")])])],1)],1),_c('v-divider'),_c('v-data-table',{staticClass:"table",attrs:{"items":_vm.items,"item-key":"step_id","headers":_vm.headers,"items-per-page":_vm.pageSize,"height":_vm.tableSize,"loading":_vm.loading,"loader-height":"2","search":_vm.search,"loading-text":"Loading Closing Steps...Please wait","no-data-text":"Select a Period and Year","dense":"","hide-default-footer":"","fixed-header":""},on:{"update:items":function($event){_vm.items=$event}},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status)?_c('span',{class:_vm.getStatusFont(item.status)},[_vm._v(_vm._s(item.status))]):_c('span',[_vm._v("Pending Execution")])]}},{key:"item.confirmed",fn:function(ref){
var item = ref.item;
return [(item.stage === 'Pre-check')?_c('span',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({directives:[{name:"show",rawName:"v-show",value:(!item.confirmed),expression:"!item.confirmed"}],attrs:{"disabled":!_vm.billingUser,"icon":""},on:{"click":function($event){return _vm.confirmStep(item)}}},on),[_c('v-icon',{staticClass:"edit",attrs:{"color":"red"}},[_vm._v("mdi-close")])],1),_c('span',{directives:[{name:"show",rawName:"v-show",value:(item.confirmed),expression:"item.confirmed"}]},[_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-checkbox-marked-circle-outline")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.confirmed ? 'Step confirmed' : 'Click to confirm'))])])],1):_c('span')]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({directives:[{name:"show",rawName:"v-show",value:(item.stage==='Closing' || item.stage === 'Post'),expression:"item.stage==='Closing' || item.stage === 'Post'"}],attrs:{"disabled":!_vm.billingUser || item.status !=='Failed',"icon":""},on:{"click":function($event){return _vm.showConfirmation(item, 'restart')}}},on),[_c('v-icon',{staticClass:"edit"},[_vm._v("mdi-restart")])],1)]}}],null,true)},[_c('span',[_vm._v("Restart")])])]}}],null,true)}),_c('v-divider'),_c('v-toolbar',{attrs:{"flat":"","color":"#fafafa"}},[(!_vm.canPost)?_c('v-btn',{staticClass:"white--text",attrs:{"disabled":!_vm.canSubmit,"height":"40","color":"#0c67a5"},on:{"click":function($event){return _vm.showConfirmation({}, 'submit')}}},[_vm._v(" Start Closing"),_c('v-icon',{staticClass:"ml-1",attrs:{"color":"#fff"}},[_vm._v("mdi-arrow-right-drop-circle-outline")])],1):_vm._e(),(_vm.canPost)?_c('v-btn',{staticClass:"white--text",attrs:{"height":"40","color":"#0c67a5"},on:{"click":function($event){return _vm.showConfirmation({}, 'post')}}},[_vm._v(" Post Recaps"),_c('v-icon',{staticClass:"ml-1",attrs:{"color":"#fff"}},[_vm._v("mdi-arrow-right-drop-circle-outline")])],1):_vm._e()],1),_c(_vm.component,{key:_vm.componentKey,tag:"Component",attrs:{"value":_vm.modal,"period":_vm.period,"year":_vm.year,"selectedStep":_vm.selectedStep,"action":_vm.action,"getClosingSteps":_vm.getClosingSteps},on:{"update:value":function($event){_vm.modal=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }