var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"ma-0"},[_c('AccrualDetail',{attrs:{"value":_vm.accrualDetail,"selectedAccrual":_vm.selectedAccrual,"ads":_vm.ads,"manufacturers":_vm.manufacturers,"suppliers":_vm.suppliers,"getAccruals":_vm.getAccruals,"formatCurrency":_vm.formatCurrency,"toggle":_vm.toggle,"edit":_vm.edit},on:{"update:value":function($event){_vm.accrualDetail=$event}}}),_c('v-data-table',{staticClass:"table",attrs:{"sort-by":"adId","height":_vm.tableSize,"loading":_vm.loading,"headers":_vm.headers,"items":_vm.accruals,"items-per-page":_vm.pageSize,"expanded":_vm.expanded,"search":_vm.search,"loader-height":"2","color":"#fff","fixed-header":"","hide-default-footer":"","dense":"","loading-text":"Loading Accruals... Please Wait","no-results-text":"No Matching Accruals Found","no-data-text":"Enter Accrual ID To View Existing Accruals"},on:{"update:headers":function($event){_vm.headers=$event},"update:items":function($event){_vm.accruals=$event},"click:row":_vm.openAccrualModal},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticStyle:{"cursor":"default"},attrs:{"flat":"","color":"#fafafa"}},[_c('v-toolbar-title',{staticClass:"ml-2 title"},[_vm._v("Meat-Accruals")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-col',{attrs:{"cols":"2"}},[_c('v-text-field',{staticClass:"mt-7",attrs:{"background-color":"#fff","label":"Filter Accruals","prepend-inner-icon":"mdi-filter-outline","autocomplete":"off","dense":"","outlined":"","clearable":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.getAccrualById($event)}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-menu',{attrs:{"offset-y":_vm.offset},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"white--text ml-2",staticStyle:{"background":"#0D68A5"},attrs:{"color":"#0D68A5","height":"40"}},on),[_vm._v(" Accrual"),_c('v-icon',[_vm._v("mdi-plus")])],1)]}}])},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.openAccrualModal('Standard')}}},[_c('v-list-item-title',[_vm._v("Standard")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.openAccrualModal('Non-Standard')}}},[_c('v-list-item-title',[_vm._v("Non-Standard")])],1)],1)],1),_c('v-spacer'),_c('v-toolbar-title',{staticClass:"ml-2 title",class:{ active: _vm.toggle === 'standard' },on:{"click":function($event){_vm.toggle = 'standard'}}},[_vm._v(" Standard ")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-toolbar-title',{staticClass:"title mr-4",class:{ active: _vm.toggle === 'non-standard' },on:{"click":function($event){_vm.toggle = 'non-standard'}}},[_vm._v(" Non-Standard ")])],1),_c('v-divider')]},proxy:true},{key:"item.beginning_date",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDates(item.beginning_date)))])]}},{key:"item.ending_date",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDates(item.ending_date)))])]}},{key:"item.billing_date",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDates(item.billing_date)))])]}},{key:"footer",fn:function(){return [_c('v-divider'),_c('v-toolbar',{attrs:{"flat":"","color":"#fafafa"}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-spacer'),_c('v-col',{staticStyle:{"max-width":"150px"},attrs:{"cols":"2"}},[_c('v-select',{staticClass:"mt-6",attrs:{"dense":"","items":_vm.pageSizes,"label":"Items Per Page","select":"","menu-props":{
                                top: true,
                                offsetY: true,
                                maxHeight: 500,
                            }},on:{"change":function($event){return _vm.getAccruals(_vm.toggle)}},model:{value:(_vm.pageSize),callback:function ($$v) {_vm.pageSize=$$v},expression:"pageSize"}})],1),_c('v-btn',{staticClass:"mx-2",attrs:{"icon":"","large":"","disabled":_vm.disablePrevious},on:{"click":function($event){return _vm.previous()}}},[_c('v-icon',[_vm._v("mdi-chevron-left")])],1),_c('small',[_vm._v("Page "+_vm._s(_vm.page))]),_c('v-btn',{staticClass:"mx-2",attrs:{"icon":"","large":"","disabled":_vm.disableNext},on:{"click":function($event){return _vm.next()}}},[_c('v-icon',[_vm._v("mdi-chevron-right")])],1)],1)],1)]},proxy:true}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }